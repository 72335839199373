import React, { useState } from "react"
import TimelineNav from "../../../components/timelinenav/timelineNav"
import CustomButton from "../../../components/Button/button";
import { TextField } from "@mui/material";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg"
import addIconWhite from "src/assets/images/addiconwhite.svg"
import activateIcon from "src/assets/images/activateicon.svg"
import swapIcon from "src/assets/images/swapicon.svg"
import upIcon from "src/assets/images/upicon.svg"
import infoIcon from "src/assets/images/informationcircle.svg"
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg"

import "../flics.css"
import { Col, Row } from "react-bootstrap";
import { sendTokensToActivateFlics } from "src/web3/ActivateFlics";

import Balance from 'src/pages/mywallet/components/balance';

import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

function ActivateFlics({
  amount,
  setAmount,
  setSliderPage
}) {
  const [flicsAmount, setFlicsAmount] = useState(amount ?? 100);
  
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const spinner = useSpinner();

  const handleSliderChange = (_, newValue) => {
    setFlicsAmount(newValue);
  };


  return (
    <div className='page-page-wrapper flics-page-container'>
      <div className="inner-pages-container">
        <div className="half-pnk">
          {/*<div className="inner-pages-container-wrap">*/}
            <h1 className="page-title">Activate FLICS</h1>
            <div className="flics-wallet-balance">Wallet Balance <span><img src={dropdTokanIcon} /><Balance /></span></div>
          {/*</div>*/}
        </div>

        <div className="inner-pages-container-wrap">
          <div className="flics-popup-conainer">

            <div className="flics-txt">Enter FLICS amount you want to buy</div>

            <div className="amount-input-field">
              <TextField
                id="outlined-start-adornment"
                variant="outlined"
                size='small'
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "1px solid",
                      borderRadius: "12px",
                      borderColor: "#000000de"
                    }
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><img src={dropdTokanIcon} /></InputAdornment>,
                }}
                value={flicsAmount}
                onChange={(event) => {
                  const _amount = (Number(event.target.value) === 'NaN' ? 0 : Number(event.target.value));
                  setFlicsAmount(_amount);
                }}
              />
            </div>
          </div>
          <Row>
            <Col lg={3} style={{ "marginLeft": "-10px" }}>
              <span
                style={{
                  float: "right",
                  color: '#6A7587',
                  fontSize: 10,
                }}
              >
                Min: 100
              </span>
            </Col>
            <Col lg={7}>
              <span
                style={{
                  float: "right",
                  color: '#6A7587',
                  fontSize: 10,
                }}
              >
                Max: 100,000
              </span>
            </Col>
          </Row>
          <div>
            <Box sx={{ width: 300, marginLeft: 3 }}>
              <Slider
                value={flicsAmount}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={100}
                max={100000}
                onChange={(_, newValue) => setFlicsAmount(newValue)}
              />
            </Box>
          </div>

          <div className="button-wrap">
            <CustomButton onClick={
              async () => {
                spinner.setLoadingState(true);

                if (!authProvider) {
                  const smartWallet = await initWallet();
                  authProvider = smartWallet;
                }

              
                sendTokensToActivateFlics(authProvider, "100");

                setAmount(flicsAmount);
                setSliderPage("flicsmain");
                // sendTokensToActivateFlics("100")
                spinner.setLoadingState(false);
              }
            }>Activate FLICS</CustomButton>
          </div>
        </div>

        
      </div>
    </div >
  );
}

export default ActivateFlics;
