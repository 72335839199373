import React, { useState, useEffect } from "react"
import TimelineNav from "../../../components/timelinenav/timelineNav"
import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg"
import addIconWhite from "src/assets/images/addiconwhite.svg"
import activateIcon from "src/assets/images/activateicon.svg"
import swapIcon from "src/assets/images/swapicon.svg"
import upIcon from "src/assets/images/upicon.svg"
import infoIcon from "src/assets/images/informationcircle.svg"
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg"

import { getFlics, postFlics } from "src/axios/POST_API";


import "../flics.css"
import { Col, Row } from "react-bootstrap";

function FlicsHistory({ setSliderPage }) {
  const [flicsHistoryList, setFlicsHistoryList] = useState([]);
  
  useEffect(() => {
      const fetchData = async () => {
        try {
          const flicsList = await getFlics();
          setFlicsHistoryList(flicsList);
        } catch (error) {
          console.error('Error fetching flics:', error);
        }
      };

      fetchData(); 
    }, []);

  return (
    <div className='page-page-wrapper flics-page-container flics-history'>
      <div className="inner-pages-container">

        <div className="half-pnk">
          {/*<div className="inner-pages-container-wrap">*/}
            <h1 className="page-title">FLICS History</h1>
          {/*</div>*/}
        </div>

        <div className="inner-pages-container-wrap flics-details">

        {flicsHistoryList.map((flics, index) => (


          <div className="flics-popup-conainer">
            <Row className="mb-3">
              <Col md={4}>
                <span className="mt-2">With</span>
              </Col>
              <Col md={8}>
                <span className="user-details">
                  <span className="img-circle"><img src={avatar} /></span>
                  <span className="user-name">{flics.nickname}</span>
                </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <span>FLICS ID</span>
              </Col>
              <Col md={8}>
                <span>{flics.flics_id}</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <span>Amount:</span>
              </Col>
              <Col md={8}>
                <span>
                  <img src={dropdTokanIcon} />
                </span>
                <span className="ms-2">{flics.flics_amount/ 1e18}</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <span>Activated on:</span>
              </Col>
              <Col md={8}>
                <span>{flics.flics_activated_date}</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <span>Ended on:</span>
              </Col>
              <Col md={8}>
                <span>{flics.flics_end_date}</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <span>Status</span>
              </Col>
              <Col md={8}>
                <span>Terminated in 35 days</span>
              </Col>
            </Row>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FlicsHistory;
