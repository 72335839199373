import React, { useEffect, useState } from "react";
import avatar from "src/assets/images/avatar.jpeg";
import "./timeline.css";
import Simpleinput from "../../components/simpleinput/simpleinput";
// import Timelinecard from "./timelinecard/timelinecard";
import PostCard from "../../components/PostCard/PostCard";
import TimelineNav from "../../components/timelinenav/timelineNav";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { GET_TIMELINE } from "../../axios/GET_API";
import { LIKE_POST } from "../../axios/POST_API";
import SkeletonLoader from "../../components/SkeletonLoader/skeletonLoader";
import { useSkeleton } from "../../context/skeletoncontext/skeletoncontext";
import { useParams } from "react-router-dom";
import FloatingToolbar from "../../components/FloatingToolbar";
import RetrieveToken from "../../utils/HelperFunctions/RetrieveToken";
import CustomToast from "../../components/CustomToast/CustomToast";

// Firebase Notification Permissions
import { messaging, getToken, onMessage } from "../../firebase";
import NotificationPermissionPopup from "../../components/NotificationPermissionPopup/NotificationPermissionPopup";
import { USER_META_FCM_TOKEN } from "../../axios/POST_API";


const { REACT_APP_CDN_HOST } = process.env;

function Timeline() {
  const { did } = useParams();
  const skeleton = useSkeleton();
  const spinner = useSpinner();
  const history = useNavigate();
  const [timeline, setTimeline] = useState();
  const [timelineError, setTimelineError] = useState()
  const [timelineNav, setTimelineNav] = useState()
  const [friendRequestStatus, setFriendRequestStatus] = useState(null);
  const [showFriendRequestBar, setShowFriendRequestBar] = useState(false);
  const [friendRequestBarMessage, setFriendRequestBarMessage] = useState('');
 
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [notificationBlocked, setNotificationBlocked] = useState(false);
  const [notificationErrorOccurred, setNotificationErrorOccurred] = useState(false);

  // Request permission to send notifications
  const requestNotificationPermission = async (grantPermission) => {
    if (grantPermission) {
      try {
        spinner.setLoadingState(true);

        const token = await getToken(messaging, {
          vapidKey: "BDqNdTe7Wj9RdOTdOBDiX9WC0gMDYTICs40ZyKU8by3aLXqPUukvKfaUB5VAN7aKQ3O45F_W3JZeS1XUM595HCA" 
        });

        let response = await USER_META_FCM_TOKEN(token);

        // Update local storage to reflect permission granted
        localStorage.setItem("notificationPermissionGranted", "true");
        localStorage.removeItem("notificationPermissionDenied");

        setShowPermissionPopup(false);
        spinner.setLoadingState(false);

      } catch (error) {
        console.error("Permission denied or error occurred", error);
        setNotificationErrorOccurred(true);
        setShowPermissionPopup(false);
        spinner.setLoadingState(false);
      }
    } else {
      localStorage.setItem("notificationPermissionDenied", "true");
      setNotificationBlocked(true);
      setShowPermissionPopup(false);
      spinner.setLoadingState(false);
    }
  };

  // useEffect to Trigger the Notification Permission Popup
  useEffect(() => {
    const checkNotificationPermission = async () => {
      const permission = Notification.permission;
      const isDenied = localStorage.getItem("notificationPermissionDenied");

      if (permission === "default" && !isDenied) {
        setShowPermissionPopup(true);

        // Set a timeout to handle cases where the user doesn't respond
        setTimeout(() => {
          if (Notification.permission === "default") {
            setNotificationBlocked(true);
            setShowPermissionPopup(false);
            spinner.setLoadingState(false);
          }
        }, 10000); // 10 seconds timeout

      } else if (permission === "granted") {
        localStorage.setItem("notificationPermissionGranted", "true");
        localStorage.removeItem("notificationPermissionDenied");

        // Re-fetch the token and update if necessary
        await requestNotificationPermission(true);

      } else if (permission === "denied") {
        localStorage.setItem("notificationPermissionDenied", "true");
        localStorage.removeItem("notificationPermissionGranted");

        setNotificationBlocked(true);
      }
    };

    checkNotificationPermission();
  }, []);

  const handleCloseToast = () => {
    setNotificationBlocked(false);
    setNotificationErrorOccurred(false);
  };
  
  const timelineData = async () => {
    try {
      let response = await GET_TIMELINE();
      setTimeline(response);
      if (response?.data?.posts.length == 0) {
        setTimelineError("No posts to show")
      }
      var temp_tl = response;
      temp_tl.data.is_profile_complete = true;
      localStorage.setItem("timeline.response", JSON.stringify(temp_tl));
      skeleton.setLoadingState(false);
    } catch (error) {
      skeleton.setLoadingState(false)
      console.log(error, "this is the timeline error");
      setTimelineError("No posts to show")
    }
  };

  const likePost = (pid) => {
    try {
      LIKE_POST(pid);
    } catch (error) {
      console.log(error);
    }
  };
  const handleProfile = () => {
    history("/profile");
  };

  const handlePost = () => {
    history("/createpost");
  };

  useEffect(() => {
    spinner.setLoadingState(false);
    
    setTimelineNav(true)
    skeleton.setLoadingState(true);
    let item = localStorage.getItem("token");
    if (!item) {
      history("/login");
    }
    let _response = localStorage.getItem("timeline.response");
    if (_response) {
      setTimeline(JSON.parse(_response));
    }
    timelineData();
  }, []);

  useEffect(() => {
    if (timeline) {
      if (timeline?.data.is_profile_complete == false) {
        history("/userdetails/" + timeline?.data.incomplete_profile_label)
      }
      skeleton.setLoadingState(false);
    }

  }, [timeline])

  let userProfileImage = "";
  if (timeline?.data?.user_profile?.profile_pics != null) {
    userProfileImage = timeline?.data?.user_profile?.profile_pics[0];
    userProfileImage =
      `${REACT_APP_CDN_HOST}` + userProfileImage.replace("//", "/");
  }


  return (
    <div className="timeline-container" style={
      showFriendRequestBar ? { 'marginTop': '8%' } : {}
    }
    >
      {showFriendRequestBar &&
        (<div className="floatingToolbar" style={{ 'top': '2%' }}>
          <span className="profile-icons-container">
            {friendRequestBarMessage}
          </span>
          {
            (friendRequestStatus === 'success' || friendRequestStatus === 'error')
            &&
            (<span className="close-icon" onClick={() => setShowFriendRequestBar(false)}>x</span>)
          }
        </div>)
      }
      {skeleton.isLoading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <TimelineNav />
          <div className="search-box-container">
            <div
              onClick={handlePost}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="input-container-box"><div className="input-text">Whats on your mind ?</div></div>
            </div>
            <div onClick={handleProfile} className="avatar-container">
              <img
                id="cursor"
                className="avatar-image"
                src={userProfileImage}
                alt="avatar"
              ></img>
            </div>
          </div>
          <div style={{paddingBottom: "60px" }}>
            {timeline?.data?.posts?.map((item, index) => {
              let postImg = "";
              if (item.images != null) {
                postImg = item.images[0];
                postImg = `${REACT_APP_CDN_HOST}` + postImg;
              }

              let profileImage = "";
              if (item.post_from.profile_pics != null) {
                profileImage = item.post_from.profile_pics[0];
                profileImage =
                  `${REACT_APP_CDN_HOST}` + profileImage.replace("//", "/");
              }
              let postDate = moment(item.created_at).format("MMM D, h:mm a");
              moment.updateLocale("en", {
                relativeTime: {
                  past: "%s",
                },
              });
              let memberSince = "";
              memberSince = moment(item.post_from.joined_at).fromNow();
              return (
                <div style={{ marginBottom: "5px",}}>
                  <PostCard
                    postComments={item.comments.length}
                    post_id={item.pid}
                    did={item.post_from.did}
                    postProfilePic={profileImage}
                    userName={item.post_from.nick_name}
                    postText={item.text}
                    postLikes={item.likes}
                    profileLikeCount={item.post_from.like_count}
                    postImage={postImg}
                    postDate={postDate}
                    memberSince={memberSince}
                    kundliAttributes={item.post_from.kundli_attributes}
                    zone={item.post_from.zone}
                    is_friend={item.post_from.is_friend}
                    is_friend_requested_to={item.post_from.is_friend_requested_to}
                    is_friend_requested_from={item.post_from.is_friend_requested_from}
                    is_liked={item.post_from.is_like_to}
                    walletAddress={item.post_from.wallet_address}
                    showFriendRequestStatus={(status, error) => {
                      
                      setShowFriendRequestBar(true);
                      setFriendRequestStatus(status);
                      if (status === 'inprogress') {
                        setFriendRequestBarMessage("Friend request in progress!");
                      } else if (status === 'success') {
                        setFriendRequestBarMessage("Friend request sent successfully!");
                      } else if (status === 'error') {
                        setFriendRequestBarMessage("Friend request failed!");
                        console.log(error);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
          {/* <div style={{color:'red',paddingBottom:'50px'}}>{timelineError}</div> */}
          <FloatingToolbar timelineNav={timelineNav} setTimelineNav={setTimelineNav} />
        </div>
      )}
      <div className="timeline_error">{timelineError}</div>

      {/* Render the Notification Permission Popup if needed */}
      {showPermissionPopup && (
        <NotificationPermissionPopup onRequestPermission={requestNotificationPermission} />
      )}
      {notificationBlocked && (
        <CustomToast
          message="Notifications have been blocked. You can enable them in your browser settings."
          onClose={handleCloseToast}
        />
      )}
      {notificationErrorOccurred && (
        <CustomToast
          message="An error occurred while requesting notification permission. You can enable them in your browser settings."
          onClose={handleCloseToast}
        />
      )}

    </div>
  );
}

export default Timeline;