import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/header";
import CustomButton from "../../../components/Button/button";
import { useWallet } from "../../../context/wallet/WalletContext";
import { USER_VIEW_PROFILE, SIGNED_KEY } from "../../../axios/GET_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { claimDRPD } from "src/web3/claim";
import { subscribe } from "src/web3/subscribe";
import { ethers, Wallet } from "ethers";

// Free tokens for males is: 250 DRPD
// Free tokens for females is: 250 DRPD
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";


function Claim({ setSliderPage }) {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const { t, i18n } = useTranslation();
  const [popupVisiblity, setPopupVisiblity] = useState(true);
  const history = useNavigate();
  const spinner = useSpinner();
  const { active, account, activate, deactivate, connector, library } = useWeb3React();
  const wallet = useWallet();


  const handleBack = () => {
    setSliderPage("success");
  };

  const handleClaimDRPD = async () => {
    spinner.setLoadingState(true);
    try {
      const response = await SIGNED_KEY();
      const sign = response.data.signed_key.signature;
      const hash = response.data.signed_key.message_hash;

      // Split the signature into v, r, and s
      const sigR = "0x" + sign.slice(2, 66);
      const sigS = "0x" + sign.slice(66, 130);
      const sigV = parseInt(sign.slice(130, 132), 16);

      // Convert the values to the desired format
      const signV = sigV;
      const signR = ethers.utils.hexZeroPad(sigR, 32);
      const signS = ethers.utils.hexZeroPad(sigS, 32);

      const signedKeyArray = [signV, signR, signS];

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      const txReciept = await claimDRPD( authProvider, hash, signedKeyArray, () => { history("/timeline")  } );

    } catch (error) {
      console.log("error", error);
    }
    finally {
      spinner.setLoadingState(false);
    }
  };

  return (
    <div className="signup-screen-wrapper">
      <Header />

      <div className="entry-wrap" style={{ marginTop: "0px" }}>
        <h1 className="page-title-big">Claim</h1>
        <div className="page-desc">
          <p>
            50 DRPD coins per month subscription fee will be deducted from your
            wallet to access selected features
          </p>
          <p>5 DRPD coins per swipe will be deducted</p>
        </div>
      </div>

      <div className="content-wrap">
        <div className="text-info-popup">
          <span>
            Congratulations! Claim 1000 DRPD token bonus, Approve token deduction for recurring subscription & one-time 40 swipes.
          </span>
        </div>
      </div>

      <div className="button-wrap">
        <CustomButton onClick={handleClaimDRPD}>Claim</CustomButton>
      </div>
    </div>
  );
}

export default Claim;
