import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";

import logo from "src/assets/images/dropdsmall.svg"
import dropd from "src/assets/images/dropdlogo.svg"
import line from "src/assets/images/line.svg"

import Selectinterest from "./interestSelect/selectinterest"
import Email from "./email/email"
import Name from "./name/name"
import Gender from "./gender/gender"
import Genderpreferences from "./genderpreferences/genderpreferences"
import Uploadphoto from "./Uploadphoto/uploadphoto"
import leftarrow from "src/assets/images/leftarrow.svg"
import Attributes from "./attributes/attributes"
import Partnerattributes from "./partnerattributes/partnerattributes"
import Zone from "./zone/zone"
import Knowbetter from "./knowbetter/knowbetter"
import CreateProfile from "../create/createProfile"
import { UPDATE_PROFILE, LOGIN, registerPhone, verifyPhone } from "src/axios/POST_API";
import Success from "./success/success"
import { useNavigate } from "react-router-dom"
import { GET_INTERESTS } from "src/axios/GET_API"
import Userdetailsnav from "../../components/UserDetailsNav/userdetailsnav"
import ConnectWalletDropd from "../connectwallet/components/connectwalletmain"
import ConnectedWallet from "../connectwallet/components/walletconnected"
import Claim from "./claim/claim"
import Subscription from "./subscription/subscription"

import ConnectWalletSuccess from "../connectwallet/components/walletsuccess"
import { useParams } from "react-router-dom"
import MobileInput from "src/components/PhoneInput/PhoneInput";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import Phone from "src/pages/userdetails/phone/Phone";

import ProgressBar from "src/components/ProgressBar/ProgressBar";
import "./signup.css";

function UserDetails() {
  const spinner = useSpinner();
  const history = useNavigate()
  const { page } = useParams()
  const [interests, setInterests] = useState([])
  const [imageFiles, setImagefiles] = useState()
  const [age, setAge] = useState()
  const [gender, setGender] = useState()
  const [selectedGender, setSelectedGender] = useState();
  const [genderpreference, setGenderPreference] = useState()
  const [personkundliattributes, setPersonkundliAttributes] = useState([])
  const [partnerkundliattributes, setPartnerkundliattributes] = useState([])
  const [zone, setZone] = useState();
  const [phone, setPhone] = useState();
  const [renderOtp, setRenderOtp] = useState(false);
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState()

  // Calculating Steps
  // const [sliderPage, setSliderPage] = useState("interests");?
  

  const getCurrentStep = (page) => {
    const steps = {
        interests: 1,
        profile_pics: 2,
        nick_name: 3,
        gender: 4,
        gender_preference: 5,
        person_kundli_attributes: 6,
        partner_kundli_attributes: 7,
        zone: 8,
        knowbetter: 9,
        claim: 10,
        subscription: 10,
    };
    return steps[page] || 1;
  };

  const getInterests = async () => {
    const response = await GET_INTERESTS()
    setInterests(response)
  }


  const handleProfileUpdate = () => {
    UPDATE_PROFILE({ age: "25", genderpreference: genderpreference, gender: gender, personkundliattributes: personkundliattributes, partnerkundliattributes: partnerkundliattributes, zone: zone, interests: interests })
  }
  useEffect(() => {
    spinner.setLoadingState(false);
    getInterests();
  }, []);

  const [sliderPage, setSliderPage] = useState();
  const totalSteps = 11; 
  const currentStep = getCurrentStep(sliderPage);

  useEffect(() => {
    setSliderPage(page);
  }, [page]);

  const renderSlider = () => {
    switch (sliderPage) {
    case "interests":
      return (
        <Selectinterest
          setSliderPage={setSliderPage}
          setInterests={setInterests}
          interests={interests}
        />
      );
    case "profile_pics":
      return (
        <Uploadphoto
          setSliderPage={setSliderPage}
          setImagefiles={setImagefiles}
        />
      );
    case "nick_name":
      return (
        <Name
          setSliderPage={setSliderPage}
        />
      )
    case "gender":
      return (
        <Gender
          setSliderPage={setSliderPage}
          setGender={setGender}
        />
      )
    case "gender_preference":
      return (
        <Genderpreferences
          setSliderPage={setSliderPage}
          selectedGender={gender}
          setGenderPreference={setGenderPreference}
        />
      );
    case "person_kundli_attributes":
      return (
        <Attributes
          setSliderPage={setSliderPage}
          setPersonkundliAttributes={setPersonkundliAttributes}
          personkundliattributes={personkundliattributes}
        />
      )
    case "partner_kundli_attributes":
      return (
        <Partnerattributes
          setSliderPage={setSliderPage}
          setPartnerkundliattributes={setPartnerkundliattributes}
          partnerkundliattributes={partnerkundliattributes}
        />
      );
    case "zone":
      return (
        <Zone
          setSliderPage={setSliderPage}
          setZone={setZone}
        />
      );
    case "knowbetter":
      return (
        <Knowbetter
          setSliderPage={setSliderPage}
          handleProfileUpdate={handleProfileUpdate}
        />
      );
    case "claim":
      return (
        <Claim
          setSliderPage={setSliderPage}
        />
      );
    case "subscription":
      return (
        <Subscription
          setSliderPage={setSliderPage}
        />
      );
    case "success":
      return (
        <Success
          setSliderPage={setSliderPage}
        />
      );
    default:
      return <></>;
    }
  }


  const handleSendOtpMobile = async () => {
    const response = await registerPhone({ phone });
    if (response.data.status == "success") {
      setRenderOtp(true);
    }
  };

  const handleSubmitOtp = async (oneTimePassword) => {
    setOtp(oneTimePassword);
    try {
      if (oneTimePassword.length === 6) {
        spinner.setLoadingState(true)
        const response = await verifyPhone({ phone, otp: oneTimePassword });
      }
    } catch (error) {
      spinner.setLoadingState(false)
    }
  }

  const renderSendOtpButton = () => {
    return (<div id="cursor" onClick={handleSendOtpMobile} style={{ position: "relative", left: "15px", top: "12px", color: "#EC1C80", fontSize: "12px", fontWeight: "400" }} >{t("send-otp.1")}</div>)
  }

  return (
    <div className='login-page-wrapper'>
      <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      {renderSlider()}
    </div>
  )
}

export default UserDetails