import React, { useState, useEffect } from 'react';
import CustomButton from "../../../components/Button/button";
import { addSwipeFunds } from "src/web3/SwipeFunds";
import { GET_VIEWMATCH } from "../../../axios/GET_API";
import { useAuth } from "src/context/web3authProvider/AuthContext";
import useWeb3Auth from "src/web3/useWeb3Auth";

const TopUpModal = ({ credits }) => {
  const { authProvider: initialAuthProvider } = useAuth(); // Renaming to avoid confusion
  const { initWallet } = useWeb3Auth();
  
  const [authProvider, setAuthProvider] = useState(initialAuthProvider);
  const [loading, setLoading] = useState(false);
  const [transactionPending, setTransactionPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [creditsCounts, setCreditsCounts] = useState();
  const [creditsUpdated, setCreditsUpdated] = useState(false);
  const [polling, setPolling] = useState(false);

  
  
  const handleAddSwipeFunds = async () => {
    setLoading(true);
    setTransactionPending(true);
    let noOfView = "10";

    try {
      let provider = authProvider;

      if (!provider) {
        const smartWallet = await initWallet();
        setAuthProvider(smartWallet);
        provider = smartWallet; // use the new provider
      }

      const txRecipt = await addSwipeFunds(provider, noOfView);

      if (txRecipt) {
        setSuccessMessage("Transaction for swipe credits has been received. We're loading credits into your account...");
        startPolling();
        console.log(txRecipt);
      }
    } catch (error) {
      console.error(error);
      setSuccessMessage("An error occurred while processing your transaction.");
    } finally {
      setLoading(false);
    }
  };

  const startPolling = () => {
    setPolling(true);
  };

  const stopPolling = () => {
    setPolling(false);
  };

  useEffect(() => {
    setCreditsCounts(credits);
    let intervalId;

    if (polling) {
      intervalId = setInterval(async () => {
        const response = await GET_VIEWMATCH();
        if (response.data.available_match_credits > 0) {
          setCreditsCounts(response.data.available_match_credits);
          setCreditsUpdated(true);
          setSuccessMessage("Credits have been added to your account. Please press the button below to reload.");
          stopPolling();
        }
      }, 5000);
    }

    return () => clearInterval(intervalId);
  }, [polling, setCreditsCounts]);

  const handleReload = () => {
    window.location.reload(); // Reload the page to reflect changes
  };

  return (
    <div className="viewmatch-popup-container">
      <div className="viewmatch-popup">
        <div className="popup-entry">
          <div className="popup-title">Top Up to view more matches</div>
          <div className="popup-info">
            You have {creditsCounts} more matches
          </div>
        </div>

        {successMessage && <div className="success-message">{successMessage}</div>}

        {/* Conditionally render the popup-action section */}
        {!creditsUpdated && (
          <div className="popup-action">
            <div className="popup-details">50 DRPD tokens will be deducted for 10 swipes.</div>
            <CustomButton
              onClick={handleAddSwipeFunds}
              style={{ width: "100%" }}
              disabled={loading || transactionPending || creditsUpdated}
            >
              {transactionPending ? "Processing..." : "Top Up"}
            </CustomButton>
          </div>
        )}

        
        {creditsUpdated && (
          <CustomButton onClick={handleReload} style={{ width: "100%" }}>
            Reload
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default TopUpModal;