// import React from 'react'
import TimelineNav from "../../components/timelinenav/timelineNav";

// Assets
import avatar from "src/assets/images/avatar.jpeg";
import avatar2 from "src/assets/images/avatar2.jpeg";
import verified from "src/assets/images/verified.svg";
import coupleimage from "src/assets/images/coupleimage.svg";
import funny from "src/assets/images/funny.svg";
import leader from "src/assets/images/leader.svg";
import nomad from "src/assets/images/nomad.svg";
import caregiver from "src/assets/images/caregiver.svg";
import passionatelover from "src/assets/images/passionatelover.svg";
import BreadEarner from "src/assets/images/attributeIcons/BreadEarner.svg";
import GenderFluid from "src/assets/images/attributeIcons/GenderFluid.svg";
import GoodParent from "src/assets/images/attributeIcons/GoodParent.svg";
import HomeMaker from "src/assets/images/attributeIcons/HomeMaker.svg";
import Homosexual from "src/assets/images/attributeIcons/Homosexual.svg";
import Straight from "src/assets/images/attributeIcons/Straight.svg";
import Thinker from "src/assets/images/attributeIcons/Thinker.svg";
import "./viewmatch.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoaderViewmatch from "src/components/LoaderViewmatch/LoaderViewmatch";
import GuideViewmatch from "src/components/GuideViewmatch/GuideViewmatch";

import moment from "moment";
import React, { useState, useMemo, useRef, useEffect } from "react";

// Modules
import { useWeb3React } from "@web3-react/core";
import { Grid } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import TinderCard from "react-tinder-card";

// Components
import Friendbutton from "../../components/Friendbutton/Friendbutton";
import CustomButton from "../../components/Button/button";
import FloatingToolbar from "../../components/FloatingToolbar";
import {
  useSpinner,
  UseSpinnerProvider,
} from "../../context/loaderContext/globalSpinnerContext";

// APIs
import { GET_VIEWMATCH, USER_VIEW_PROFILE } from "../../axios/GET_API";
import { VIEW_MATCH_SWIPE } from "../../axios/POST_API";

import { SIGNED_KEY } from "../../axios/GET_API";

// TopUp Popup
import TopUpPopup from "./components/TopUpPopup";

// WEB 3
import { addSwipeFunds } from "src/web3/SwipeFunds";
import { sendTokensForaddFriend } from "src/web3/addFriend";

import { useAuth } from "src/context/web3authProvider/AuthContext";
import useWeb3Auth from "src/web3/useWeb3Auth";

const { REACT_APP_CDN_HOST } = process.env;

const PersonAttributes = {
  leader: { image: leader, text: "Leader" },
  "bread earner": { image: BreadEarner, text: "Bread Earner" },
  "care giver": { image: caregiver, text: "Care Giver" },
  funny: { image: funny, text: "Funny" },
  "gender fluid": { image: GenderFluid, text: "Gender Fluid" },
  "good parent": { image: GoodParent, text: "Good Parent" },
  homemaker: { image: HomeMaker, text: "Home Maker" },
  homosexual: { image: Homosexual, text: "Homosexual" },
  nomad: { image: nomad, text: "Nomad" },
  "passionate lover": { image: passionatelover, text: "Passionate Lover" },
  thinker: { image: Thinker, text: "Thinker" },
  straight: { image: Straight, text: "Straight" },
};

function Viewmatch() {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const [senderAddress] = useState(localStorage.getItem("wallet_address"));

  const history = useNavigate();
  const spinner = useSpinner();

  const { account, active, activate } = useWeb3React();
  const [openAddFriendPopup, setOpenAddFriendPopup] = useState(false);
  const [viewMatch, setViewMatch] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [lastDirection, setLastDirection] = useState("left");
  const [viewmatchNav, setViewMatchNav] = useState();
  const [did, setDid] = useState();
  const [receiverWallerAddress, setReceiverWallerAddress] = useState();
  // const [matchPopup, setMatchPopup] = useState(false);
  // used for outOfFrame closure

  const [showTopUpPopup, setShowTopUpPopup] = useState(false);
  const [showNoMatches, setShowNoMatches] = useState(false);
  const [credits, setCredits] = useState(null);
  const [matchCount, setMatchCount] = useState(null);
  const [remainingMatches, setRemainingMatches] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentCard, setCurrentCard] = useState(null);
  const [swipedCards, setSwipedCards] = useState([]);

  const currentIndexRef = useRef(currentIndex);

  // Effect to fetch view match data on component mount
  useEffect(() => {
    getViewMatch();
  }, []);

  // Function to fetch view match data
  const getViewMatch = async () => {
    try {
      spinner.setLoadingState(true);
      const response = await GET_VIEWMATCH();
      setViewMatch(response);

      setCredits(response.data.available_match_credits);
      setMatchCount(response.data.total_match_count);
      setRemainingMatches(response.data.total_match_count);
      setCurrentIndex(response.data.matches.length);

      // Prioritize Top Up popup
      if (response.data.available_match_credits <= 0) {
        setShowTopUpPopup(true);
        setShowNoMatches(false); // Ensure No Matches popup is hidden
      } else if (response.data.total_match_count <= 0) {
        // Only show No Matches popup if credits are available
        setShowTopUpPopup(false);
        setShowNoMatches(true);
      } else {
        // Hide both popups if neither condition is met
        setShowTopUpPopup(false);
        setShowNoMatches(false);
      }

    } catch (error) {
      console.log("Error fetching view match:", error);
    } finally {
      spinner.setLoadingState(false);

      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  const getSignedKey = async () => {
    try {
      const response = await SIGNED_KEY();
    } catch (error) {
      console.log("Error fetching view match:", error);
    }
  };

  // Function to deduct credits
  const deductCredit = () => {
    setCredits((prevCredits) => {
      const newCredits = prevCredits - 1;
      // Check if credits are sufficient before deducting
      if (newCredits >= 0) {
        return newCredits;
      } else {
        setShowTopUpPopup(true);
        return prevCredits;
      }
    });
  };

  // Show top-up popup if credits are 0 or null, Perfom every action which depend on credits
  useEffect(() => {
    if (credits !== null && credits <= 0) {
      getSignedKey();
      setShowTopUpPopup(true);
    } else {
      setShowTopUpPopup(false);
    }
  }, [credits]);

  // Check users Remaining Matches
  useEffect(() => {
    if (remainingMatches !== null && remainingMatches <= 0) {
      setShowNoMatches(true);
    } else {
      setShowNoMatches(false);
    }
  }, [remainingMatches]);

  // Function to deduct remaining matches count
  const deductRemainingMatch = () => {
    setRemainingMatches((prevRemainingMatches) => {
      const newRemainingMatches = prevRemainingMatches - 1;
      // You can add additional checks or conditions here if needed
      return newRemainingMatches >= 0
        ? newRemainingMatches
        : prevRemainingMatches;
    });
  };

  const childRefs = useMemo(
    () =>
      Array(viewMatch?.data.matches.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const handleAddSwipeFunds = async () => {
    spinner.setLoadingState(true);
    let noOfView = "10";
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      const txRecipt = await addSwipeFunds(authProvider, noOfView);

      if(txRecipt){

      }
    } catch (error) {
      console.log(error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  const recordSwipe = async (direction, did) => {
    try {
      const response = await VIEW_MATCH_SWIPE(direction, did);
      if (response.status !== 200 || !response.data.success) {
        console.error('Swipe recording failed:', response.data.message);
      }
    } catch (err) {
      console.error('Failed to record swipe:', err);
      return;
    }
  };

  // handleAddFriendRequest Web3
  const handleAddFriendRequest = async (
    authProvider,
    senderAddress,
    receiverAddress
  ) => {
    try {
      const txRecipt = await sendTokensForaddFriend({
        authProvider,
        senderAddress,
        receiverAddress,
      });
      setOpenAddFriendPopup(false);
    } catch (error) {
      alert(error);
    }
  };

  const cancelAddFriendAction = () => {
    setOpenAddFriendPopup(false);
    setCurrentCard(null);
  };
  const handleOnSwipe = async (direction, index, did, wallerAddress) => {
    setCurrentIndex(index);
    deductCredit();
    deductRemainingMatch();
    setReceiverWallerAddress(wallerAddress);

    if (direction === "left") {
      recordSwipe(direction, did);
    }

    // Like match
    if (direction === "right") {
      recordSwipe(direction, did);
    }

    // Send Add Friend Request
    if (direction === "up") {
      recordSwipe(direction, did);
      setOpenAddFriendPopup(true);
    }

    // Exclude from future matches
    if (direction === "down") {
      recordSwipe(direction, did);
    }
  };

  const handleOutOfFrame = (name, index) => {
    if (currentIndexRef.current >= index) {
      childRefs[index].current.restoreCard();
    }
  };
  return (
    <div>
    <GuideViewmatch/>
    {/*Loading condition start*/}
    {isLoading ? (
      <LoaderViewmatch/>
    ) : (
      <div className="cardContainer">
        <TimelineNav />

        
        {showTopUpPopup && (
          <TopUpPopup credits={credits} />
        )}

        {showNoMatches ? (
          <div className="viewmatch-popup-container">
            <div className="viewmatch-popup">
              <div className="popup-entry">
                <div className="popup-title">You don’t have more matches.</div>
                <div className="popup-info">Please check after sometime.</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
       

        {openAddFriendPopup ? (
          <div className="add-friend-popup-wrapper">
            <div className="add-friend-popup">
              <span>
                10 DRPD will be <br /> sent as a gift to this person
              </span>
              <CustomButton
                style={{ width: "50%" }}
                onClick={() =>
                  handleAddFriendRequest(
                    authProvider,
                    senderAddress,
                    receiverWallerAddress
                  )
                }
              >
                Send Request
              </CustomButton>
              <div style={{ marginTop: "15px" }}>
                <CustomButton
                  style={{ width: "50%" }}
                  onClick={() => cancelAddFriendAction()}
                >
                  Cancel
                </CustomButton>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {viewMatch?.data.matches.slice().reverse().map((item, matchIndex) => {
          let profileImage = "";
          if (item.profile_pics != null) {
            profileImage = item.profile_pics[0];
            profileImage =
              `${REACT_APP_CDN_HOST}` + profileImage.replace("//", "/");
          }

          moment.updateLocale("en", {
            relativeTime: {
              past: "%s",
            },
          });
          let memberSince = moment(item?.joined_at).fromNow();

          // Log the key to verify uniqueness
          console.log("Rendering card for:", item.did, "at index:", matchIndex, "Key:", item.did);

          return (
            <TinderCard
              ref={childRefs[matchIndex]}
              className="swipe"
              key={item.did || matchIndex} // Ensure uniqueness, fallback to index if necessary
              onSwipe={(dir) => handleOnSwipe(dir, matchIndex, item.did, item.wallet_address)}
            >
              <div className="viewmatch-card-wrap">
                <div className="viewmatch-container">

                  <div className="viewmatch-image-slider">
                    <Carousel
                      showIndicators={false}
                      showThumbs={false}
                      infiniteLoop={true}
                    >
                      <div className="viewmatch-image-wrap">
                        <img
                          className="viewmatch-image"
                          src={profileImage}
                          alt="avatar"
                        />
                      </div>
                    </Carousel>
                    {/*<div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="views-left">
                        <span>
                          {remainingMatches} / {matchCount}
                        </span>
                        <span>
                          Views Left {credits}/
                          {viewMatch?.data.available_match_credits}
                        </span>
                      </div>
                    </div>*/}
                  </div>

                  <div className="viewmatch-details-wrap">
                    <div className="viewmatch-count-wrap">
                      <div className="views-left">
                        <span>
                          {remainingMatches} / {matchCount}
                        </span>
                        <span>
                          Views Left {credits}/
                          {viewMatch?.data.available_match_credits}
                        </span>
                      </div>
                    </div>

                    <div className="viewmatch-details-inner-wrap">
                      <div className="viewmatch-featured-details">
                        <div className="viewmatch-label-text">
                          Match Score {item.match_score}
                        </div>
                      </div>

                      <Grid container className="viewmatch-details">
                        <Grid item xs={3}>
                          <div className="viewmatch-avatar-container">
                            <div
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "30px",
                                backgroundColor: "pink",
                                position: "relative",
                                top: "10px",
                                left: "10px",
                              }}
                            >
                              {profileImage && (
                                <img
                                  className="profile-image-container"
                                  src={profileImage}
                                  alt=""
                                ></img>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <div style={{ display: "flex" }}>
                            <div className="user-name">{item.nick_name}</div>
                            <div className="online-status"></div>
                            <div
                              style={{
                                paddingTop: "10px",
                                paddingLeft: "10px",
                              }}
                            >
                              <img src={verified} alt="verified" />
                            </div>
                          </div>
                          <div
                            style={{ paddingTop: "5px" }}
                            className="user-time-details"
                          >
                            Member since {memberSince}
                          </div>
                          <div style={{ display: "flex", paddingTop: "6px" }}>
                            <div>
                              <div className="user-time-details">Total Likes</div>
                              <div className="user-data">{item.like_count}</div>
                            </div>
                            <div style={{ paddingLeft: "30px" }}>
                              <div className="user-time-details">
                                This month
                              </div>
                              <div className="user-data">{item.like_count}</div>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          style={{ display: "flex", flexDirection: "column" }}
                          item
                          xs={4}
                        >
                          <div>
                            <img
                              className="couple-image-position"
                              src={coupleimage}
                              alt="coupleimage"
                            />
                          </div>
                          <Friendbutton
                            did={item.did}
                            is_friend={item.is_friend}
                            is_friend_requested_to={item.is_friend_requested_to}
                            is_friend_requested_from={item.is_friend_requested_from}
                            walletAddress={item.wallet_address}
                            
                          />
                        </Grid>
                      </Grid>
                      {/*<div className="viewmatch-description">*/}
                        {/*<div className="profile-description-text">
                          -I am a Semi-professional bathroom singer
                        </div>*/}
                      {/*</div>*/}
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "white",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "8px",
                          justifyContent: "space-around",
                          borderRadius: "0px 0px 17px 17px",
                        }}
                      >
                        {viewMatch?.data?.matches[
                          matchIndex
                        ]?.person_kundli_attributes?.map((attribute, index) => {
                          return (
                            <div className="iconalignment">
                              <div style={{ height: "40px" }}>
                                <img
                                  src={PersonAttributes[attribute].image}
                                  alt={attribute}
                                />
                              </div>
                              <div className="logo-text">
                                {PersonAttributes[attribute].text}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="viewmatch-scores">
                        <div className="viewmatch-items">
                          Kundali Match <span style={{ color: "#EC1C80" }}>5/5</span>
                        </div>
                        <div className="viewmatch-items">
                          Zone match{" "}
                          <span style={{ color: "#EC1C80" }}>Yes</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          paddingTop: "15px",
                        }}
                      >
                        <div>
                          <div className="viewmatch-common-traits">{item.common_languages}</div>
                          <div className="small-text">Common Language</div>
                        </div>
                        <div>
                          <div className="viewmatch-common-traits">
                            {item.common_clubs}
                          </div>
                          <div className="small-text">Common Clubs</div>
                        </div>
                        <div>
                          <div className="viewmatch-common-traits">
                            {item.common_interests}
                          </div>
                          <div className="small-text">Common Interests</div>
                        </div>
                      </div>
                      <div className="viewmatch-user-details">
                        <div className="viewmatch-items-details">
                          {item.age} years old
                        </div>
                        <div className="viewmatch-items-details">
                          {item.marital_status}
                        </div>
                        <div className="viewmatch-items-details">
                          {item.city}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TinderCard>
          );
        })}

    
        {/*<div className="view-match-guide-overlay"></div>*/}
      </div>
    )} {/* Loading Condition Close */}

    </div>
  );
}

export default Viewmatch;