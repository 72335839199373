import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { interactionABI } from "../contract/interactionABI";
import { contractABI } from "../contract/contractABI";
import { chains } from "../chain";
import { toast } from "react-toastify";
  
export const addSwipeFunds = async (authProvider, noOfView) => {
    toast.info("Sending Viewmatch Fees Transaction. Please Wait.")
    const contractAddress = chains.dropdContractAdd;
    const interactionAddress = chains.interactionContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
    );
    const interactionInstance = new ethers.Contract(
        interactionAddress,
        interactionABI,
        provider
    )
    const perSwipeFees = 5;
    const numOfView = Number(noOfView);
    const approvedAmount = ethers.utils.parseEther((numOfView * perSwipeFees).toString());

  try {
    const minTx1 = await contractInstance.populateTransaction.approve(interactionAddress, approvedAmount);
  
    const tx1 = {
      to: contractAddress,
      data: minTx1.data
    };
    const minTx2 = await interactionInstance.populateTransaction.viewMatchFees(noOfView);
  
    const tx2 = {
      to: interactionAddress,
      data: minTx2.data
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider?.sendTransaction([tx1,tx2], {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: {nonceKey: i++ }
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
  
    if (transactionHash) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};