import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";

import { chains } from "../chain";

export const getWalletBalance = async () => {

	const contractAddress = chains.dropdContractAdd;
	const provider = new ethers.providers.JsonRpcProvider(
		chains.providerUrl
	);

	const contractInstance = new ethers.Contract(
		contractAddress,
		contractABI,
		provider
	);

	const walletAddress = localStorage.getItem("wallet_address");

	try {
		const testTx = await contractInstance.balanceOf(walletAddress);
		
		const activeAmount = parseFloat(testTx)
		const testAmount = parseFloat(testTx);

		const samllAmount = (activeAmount / 1e18);
		
		if (testTx) {
			return (samllAmount);
		}
		//@ts-ignore
	} catch (error) {
		console.log(error);
		console.log('Fetch Balance Unsuccessful');
	}
};
