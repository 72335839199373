import {
    createSmartAccountClient,
    BiconomySmartAccountV2,
    PaymasterMode,
  } from "@biconomy/account";
  import { ethers } from "ethers";
  import { flicsABI } from "../contract/flicsABI";
  
  import { chains } from "../chain";
  import { toast } from "react-toastify";
    
  export const claimCashbackFlics = async (authProvider, flicsInitiator, flicsAddress) => {
    debugger
      toast.info("Sending Flics Cashback Transaction. Please Wait.")
      const provider = new ethers.providers.JsonRpcProvider(
          chains.providerUrl
      );
      
      const flicsInstance = new ethers.Contract(
          chains.flicsPoolContractAdd,
          flicsABI,
          provider
      )
  
    try {
      const minTx = await flicsInstance.populateTransaction.fullRefundReward(flicsInitiator, flicsAddress);
      
      const tx1 = {
        to: chains.flicsPoolContractAdd,
        data: minTx.data,
      };
    
      //@ts-ignore
      const userOpResponse = await authProvider?.sendTransaction(tx1, {
        paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      });
      //@ts-ignore
      const { transactionHash } = await userOpResponse.waitForTxHash();
      
  
      if (transactionHash) {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  